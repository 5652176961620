import { TextField as MuiTextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import React, { InputHTMLAttributes, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

// Trabian
import BodyText from '~components/shims/BodyText';
import LabelText from '~components/shims/LabelText';

type TextFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  fieldError?: FieldError;
  placeholder?: string;
  color?: React.ComponentProps<typeof MuiTextField>['color'];
  prefix?: string;
  suffix?: string;
};

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ label, placeholder, type, fieldError, color, ...props }, ref) => {
    return (
      <React.Fragment>
        <div className="flex flex-col flex-nowrap">
          <LabelText htmlFor={props.name} className="block font-semibold mb-2 text-sm">
            {label}
          </LabelText>
          <MuiTextField
            {...props}
            color={color}
            id={props.name}
            inputRef={ref}
            placeholder={placeholder}
            size="small"
            type={type ?? 'text'}
            variant="outlined"
            InputProps={{
              startAdornment: props.prefix ? (
                <InputAdornment position="start">{props.prefix}</InputAdornment>
              ) : null,
              endAdornment: props.suffix ? (
                <InputAdornment position="end">{props.suffix}</InputAdornment>
              ) : null,
            }}
          />
        </div>
        {fieldError && (
          <div className="mt-1">
            <BodyText className="text-red-500">{fieldError.message}</BodyText>
          </div>
        )}
      </React.Fragment>
    );
  },
);

export default TextField;
