import { AxiosError } from 'axios';
import { AuthServiceApi } from '@fintronners/react-api/src/tsoai';
import type { NextApiRequest, NextApiResponse } from 'next';
import * as yup from 'yup';
import { getStringRequiredSchema } from '@fintronners/react-api/src/utils/formUtils/schemas/commonSchemas';
import { setAuthApiCookies } from 'utils/cookie';
import { apolloClient } from '~components/graphql';
import RuntimeEnvGRPCAPI, { getAuthUrl } from '~utils/RuntimeEnvGRPCAPI';

export const loginSchema = yup.object().shape({
  username: getStringRequiredSchema(),
  password: getStringRequiredSchema(),
});

const loginCall = async (payload: { username: string; password: string }, authUrl: string) => {
  try {
    const response = await RuntimeEnvGRPCAPI.getAuthService(
      AuthServiceApi,
      authUrl,
    ).authServiceLogin(payload);
    return { response };
  } catch (error: any) {
    return { error };
  }
};

const login = async (username: string, password: string, authUrl: string) => {
  const credentials = {
    username,
    password,
  };

  const { response, error } = await loginCall(credentials, authUrl);

  if (error) {
    console.error('Error during login:', error.message);
    throw error;
  }

  if (response) {
    if (response.status === 200) {
      const loginResponse = response.data;
      return {
        accessToken: loginResponse.accessToken,
        refreshToken: loginResponse.refreshToken,
        idToken: loginResponse.idToken,
        session: loginResponse.session,
      };
    } else {
      console.error('Error during login. Call was successful but not 200. code=' + response.status);
      throw new Error('Login failed with status code: ' + response.status);
    }
  }
  throw new Error('Unexpected error during login');
};

export default async function handler(req: NextApiRequest, res: NextApiResponse) {
  try {
    const parsed = await loginSchema.validate(req.body, { abortEarly: false });
    const { username, password } = parsed;

    // log in
    try {
      const authUrl = getAuthUrl(req.headers.host);

      const { accessToken, refreshToken, idToken } = await login(username, password, authUrl);

      setAuthApiCookies(res, accessToken, refreshToken, idToken, req.headers.cookie);

      // Reset the GraphQL store in case anything was cached
      await apolloClient.cache.reset();

      res
        .status(200)
        .json({ success: true, message: 'User logged in successfully', redirect: '/' });
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        //   const authStatusCode = error.response?.status;
        const authMessage = error.response?.data?.message;
        res.status(200).json({ success: false, message: authMessage || 'Failed to log in user' });
      } else if (error instanceof Error) {
        console.error('General error:', error.message);
      } else {
        console.error('An unknown error occurred', error);
      }
    }
  } catch (validationError) {
    if (validationError instanceof yup.ValidationError) {
      const errors = validationError.inner.reduce((acc: any, err: any) => {
        if (!acc[err.path]) {
          acc[err.path] = err.message;
        }
        return acc;
      }, {});
      res.status(400).json({ success: false, errors });
    } else {
      console.error('Unexpected error during processing:', validationError);
      res.status(400).json({ success: false, message: 'Failed to validate input' });
    }
  }
}
